module.exports = function shuffle_array(array) {
  let cur_idx = array.length;
  let random_idx;

  while (cur_idx !== 0) {
    random_idx = Math.floor(Math.random() * cur_idx);
    cur_idx--;

    [array[cur_idx], array[random_idx]] = [array[random_idx], array[cur_idx]];
  }

  return array;
};
