import AppBar from "@mui/material/AppBar";
import { Link } from "react-router-dom";
import "./Navbar.css";

function Navbar() {
  return (
    <AppBar position="static" sx={{ bgcolor: "#152238" }}>
      <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
        <h1>JANGLE</h1>
      </Link>
    </AppBar>
  );
}

export default Navbar;
