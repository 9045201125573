import { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import HomePage from "./pages/HomePage/HomePage";
import Game from "./pages/Game/Game";
import "./App.css";

const theme = createTheme({
  palette: {
    mode: "dark", // Automatically adjusts the color of text to be more appropriate for dark backgrounds
  },
  typography: {
    fontFamily: "Roboto Mono, monospace", // Applies Roboto Mono globally
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#152238", // Your chosen background color
        },
      },
    },
  },
});

function App() {
  const [game, setGame] = useState(0);
  const options = [
    {
      id: "0",
      title: "Harry Potter Edition",
      link: "/game",
    },
    {
      id: "1",
      title: "Hans Zimmer Edition",
      link: "/game",
    },
  ];
  const homeTitle = "Soundtrack Trivia";
  const subTitle =
    "Guess the movie from one of its soundtracks! How many can you get correct?";

  const handleGame = function (e) {
    setGame(e.target.id);
  };
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <HomePage
                title={homeTitle}
                subtitle={subTitle}
                options={options}
                handleGame={handleGame}
              />
            }
          />
          <Route path="/game" element={<Game game={game} />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
