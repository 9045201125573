import { Paper, Button, Grid, Typography, Stack } from "@mui/material";
import { Link } from "react-router-dom";

import "./Menu.css";

function Menu({ options, title, subtitle, handleGame }) {
  return (
    <Paper elevation={3} className="menu">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className="grid"
      >
        <Typography variant="h4" align="center">
          {title}
        </Typography>
        <Typography variant="body2" align="center" sx={{ padding: 2 }}>
          {subtitle}
        </Typography>
      </Grid>
      <Stack direction="column" spacing={2} alignItems="center">
        {options.map((option, index) => (
          <Link
            to={option.link}
            key={index}
            style={{ width: "100%", textDecoration: "none" }}
          >
            <Button
              key={index}
              id={option.id}
              variant="contained"
              onClick={handleGame}
              style={{ width: "100%" }}
            >
              {option.title}
            </Button>
          </Link>
        ))}
      </Stack>
    </Paper>
  );
}

export default Menu;
