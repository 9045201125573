import Navbar from "../../components/Navbar/Navbar";
import Menu from "../../components/Menu/Menu";
import "./HomePage.css";

function HomePage({ title, subtitle, options, handleGame }) {
  return (
    <div className="App">
      <Navbar />
      <Menu
        title={title}
        subtitle={subtitle}
        options={options}
        handleGame={handleGame}
      />
    </div>
  );
}

export default HomePage;
