import {
  Paper,
  Button,
  Grid,
  Typography,
  TextField,
  Autocomplete,
} from "@mui/material";
import "./Game.css";
import Audio from "../Audio/Audio";

function Guess({
  options,
  title,
  onSubmit,
  handleGuess,
  track,
  correct,
  lives,
}) {
  return (
    <Paper elevation={3} className="menu">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className="grid1"
      >
        <Typography variant="h4" align="center">
          {title}
        </Typography>
      </Grid>
      <Grid className="grid4" justifyContent="center" alignItems="center">
        <Audio src={track} />
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        className="gridScore"
      >
        <Typography variant="h6" align="center">
          Correct: {correct}
        </Typography>
        <Typography variant="h6" align="center">
          Lives: {lives}
        </Typography>
      </Grid>
      <Grid className="grid2">
        <Autocomplete
          disablePortal
          options={options}
          renderInput={(params) => (
            <TextField {...params} label="Your guess" variant="outlined" />
          )}
          onInputChange={handleGuess}
          className="autocomplete"
        />
      </Grid>

      <Grid container direction="row" justifyContent="center" className="grid3">
        <Button variant="contained" onClick={onSubmit}>
          Submit
        </Button>
      </Grid>
    </Paper>
  );
}

export default Guess;
