export const harrypotteroptions = [
  { label: "Harry Potter and the Philosopher's Stone" },
  { label: "Harry Potter and the Chamber of Secrets" },
  { label: "Harry Potter and the Prisoner of Azkaban" },
  { label: "Harry Potter and the Goblet of Fire" },
  { label: "Harry Potter and the Order of Phoenix" },
  { label: "Harry Potter and the Half-Blood Prince" },
  { label: "Harry Potter and the Deathly Hallows Part 1" },
  { label: "Harry Potter and the Deathly Hallows Part 2" },
];
