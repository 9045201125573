import { Paper, Button, Grid, Typography, Link } from "@mui/material";
import "./Game.css";

function Answer({ title, track, handleNext }) {
  return (
    <Paper elevation={3} className="menu">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className="grid1"
      >
        <Typography variant="h4" align="center">
          {title}
        </Typography>
      </Grid>
      <Grid className="grid2" justifyContent="center" alignItems="center">
        <Typography variant="h6">
          <u>Movie</u> : {track.title}
        </Typography>
      </Grid>
      <Grid className="grid2">
        <Typography variant="h6">
          <u>Artist</u> : {track.artist}
        </Typography>
      </Grid>
      <Grid className="grid2">
        <Link href={track.link} variant="h6">
          Full Track
        </Link>
      </Grid>
      <Grid container direction="row" justifyContent="center" className="grid3">
        <Button variant="contained" onClick={handleNext}>
          Next
        </Button>
      </Grid>
    </Paper>
  );
}

export default Answer;
