import { useState, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Guess from "../../components/GameUI/Guess";
import Answer from "../../components/GameUI/Answer";
import harrypotterdata from "../../data/harrypotter/formattedharrypotter.json";
import { harrypotteroptions } from "../../data/harrypotter/harrypottermovies.js";
import hanszimmerdata from "../../data/hanszimmer/hanszimmer.json";
import { hanszimmeroptions } from "../../data/hanszimmer/hanszimmermovies.js";
import shuffle_array from "../../utils/shuffle_array";
import GameOver from "../../components/GameUI/GameOver";

const title = "Guess the Movie!";

function Game({ game }) {
  const [data, setData] = useState(
    JSON.parse(sessionStorage.getItem("data")) || []
  );
  const [tracks, setTracks] = useState(
    JSON.parse(sessionStorage.getItem("tracks")) || []
  );
  const [options, setOptions] = useState(
    JSON.parse(sessionStorage.getItem("options")) || []
  );
  const [guess, setGuess] = useState("");
  const [submit, hasSubmit] = useState(false);
  const [cur_track, setCurTrack] = useState(0);
  const [answerTitle, setAnswerTitle] = useState("");
  const [correct, setCorrect] = useState(0);
  const [lives, setLives] = useState(3);
  const [gameOver, setGameOver] = useState(false);
  const [gameOverMessage, setGameOverMessage] = useState("");

  useEffect(() => {
    sessionStorage.setItem("data", JSON.stringify(data));
    sessionStorage.setItem("tracks", JSON.stringify(tracks));
    sessionStorage.setItem("options", JSON.stringify(options));
  }, [data, tracks, options]);

  useEffect(() => {
    if (game === "0") {
      setData(harrypotterdata);
      setOptions(harrypotteroptions);
    } else if (game === "1") {
      setData(hanszimmerdata);
      setOptions(hanszimmeroptions);
    }
    setTracks(shuffle_array(data));
  }, [data, game]);

  const onSubmit = function (e) {
    hasSubmit(true);

    if (guess === tracks[cur_track]?.title) {
      setAnswerTitle("Correct!");
      setCorrect((prevCorrect) => prevCorrect + 1);
    } else {
      setAnswerTitle("Incorrect!");
      setLives((prevLives) => prevLives - 1);
    }
  };
  const handleGuess = function (e, v) {
    setGuess(v);
  };
  const handleNext = function (e) {
    hasSubmit(false);
    setCurTrack((prevTrack) => prevTrack + 1);

    if (lives === 0) {
      setGameOver(true);
      setGameOverMessage("Game Over!");
    }

    if (cur_track >= tracks.length) {
      setGameOver(true);
      setGameOverMessage("You Won!");
    }
  };
  const handlePlayAgain = function () {
    setLives(3);
    setGameOver(false);
    setCorrect(0);
    setTracks(shuffle_array(data));
  };

  const currentTrackPreview =
    tracks.length > 0 ? tracks[cur_track]?.preview : "";

  return (
    <div className="App">
      <Navbar />
      {gameOver ? (
        <GameOver
          title={gameOverMessage}
          correct={correct}
          handlePlayAgain={handlePlayAgain}
        />
      ) : submit ? (
        <Answer
          title={answerTitle}
          handleNext={handleNext}
          track={tracks[cur_track]}
        />
      ) : (
        <Guess
          options={options}
          title={title}
          onSubmit={onSubmit}
          handleGuess={handleGuess}
          track={currentTrackPreview}
          correct={correct}
          lives={lives}
        />
      )}
    </div>
  );
}

export default Game;
