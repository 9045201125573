import React, { useState } from "react";
import { Card, IconButton, Slider, Tooltip } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { useAudio } from "react-use";

const AudioPlayer = ({ src }) => {
  const [audio, state, controls] = useAudio({
    src,
    autoPlay: true,
  });

  const [lastVolume, setLastVolume] = useState(state.volume);
  const [showVolumeSlider, setShowVolumeSlider] = useState(false);

  const togglePlayPause = () => {
    if (state.paused) {
      controls.play();
    } else {
      controls.pause();
    }
  };

  const toggleMute = () => {
    if (state.volume > 0) {
      setLastVolume(state.volume);
      controls.volume(0);
    } else {
      controls.volume(lastVolume || 0.5); // Restore to last volume, or halfway if no last volume was set
    }
  };

  const volumeIcon = state.volume > 0 ? <VolumeUpIcon /> : <VolumeOffIcon />;

  return (
    <Card
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        p: 2,
        gap: 1,
        background: "#141e2f",
      }}
    >
      {audio}
      <IconButton
        onClick={togglePlayPause}
        aria-label="play/pause"
        sx={{ borderRadius: "50%" }}
      >
        {state.paused ? <PlayArrowIcon /> : <PauseIcon />}
      </IconButton>
      <Slider
        aria-label="Playback"
        value={state.time || 0}
        min={0}
        max={state.duration || 1}
        onChange={(_, value) => controls.seek(value)}
        step={0.1}
        sx={{ flex: 1 }}
      />
      <Tooltip title="Volume Control">
        <IconButton
          onMouseEnter={() => setShowVolumeSlider(true)}
          onMouseLeave={() => setShowVolumeSlider(false)}
          onClick={toggleMute}
          aria-label="mute/unmute"
        >
          {volumeIcon}
        </IconButton>
      </Tooltip>
      {showVolumeSlider && (
        <Slider
          aria-label="Volume"
          value={state.volume * 100}
          onChange={(_, value) => controls.volume(value / 100)}
          onMouseEnter={() => setShowVolumeSlider(true)}
          onMouseLeave={() => setShowVolumeSlider(false)}
          min={0}
          max={100}
          sx={{ width: 100 }}
        />
      )}
    </Card>
  );
};

export default AudioPlayer;
