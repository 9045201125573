export const hanszimmeroptions = [
  { label: "The Dark Knight Rises" },
  { label: "Dune" },
  { label: "Dunkirk" },
  { label: "Interstellar" },
  { label: "Kung Fu Panda" },
  { label: "Call of Duty Modern Warfare 2" },
  { label: "Inception" },
  { label: "Pirates of the Caribbean: Dead Man's Chest" },
  { label: "The Creator" },
  { label: "Man of Steel" },
  { label: "Black Hawk Down" },
  { label: "Pearl Harbor" },
  { label: "Da Vinci Code" },
  { label: "No Time To Die" },
  { label: "The Holiday" },
  { label: "Sherlock Holmes" },
  { label: "The Lone Ranger" },
  { label: "Sherlock Holmes: A Game of Shadows" },
  { label: "Mission: Impossible 2" },
];
