import { Paper, Button, Grid, Typography } from "@mui/material";
import "./Game.css";

function GameOver({ title, correct, handlePlayAgain }) {
  return (
    <Paper elevation={3} className="menu">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className="grid1"
      >
        <Typography variant="h4" align="center">
          {title}
        </Typography>
      </Grid>
      <Grid className="grid2" justifyContent="center" alignItems="center">
        <Typography variant="h6" textAlign="center">
          Congrats! You guessed <u>{correct}</u> soundtrack(s) correctly!
        </Typography>
      </Grid>
      <Grid container direction="row" justifyContent="center" className="grid3">
        <Button variant="contained" onClick={handlePlayAgain}>
          Play Again
        </Button>
      </Grid>
    </Paper>
  );
}

export default GameOver;
